.select-trigger {
    width: 210px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.select-popup {
    max-height: 300px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    border: 2px solid grey;
    border-radius: 10px;
    padding: 1px 0 0 0;
    background-color: white;
}

.select-option {
    width: 210px;
    margin: 0;
    padding: 0;
    background-color: white;
    border-width: 0 0 1px 0;
    border-style: dotted;
    border-color: grey;
    border-radius: 0;
}

.select-option-selected {
    background-color: lightblue;
}