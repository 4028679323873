body {
    padding: 0;
    margin: 0;
    font-family: "Noto Sans", sans-serif;
    font-size: 12pt;
    overflow: auto;
    background-color: #fbfbfb;
}

button {
    font-family: "Noto Sans", sans-serif;
    font-size: 12pt;
    border: 1px dotted grey;
    border-radius: 10px;
    background-color: #eeeeee;
    width: 120px;
    height: 35px;
    line-height: 35px;
    padding: 0 15px;
    margin: 10px 0;
}

pre {
    margin: 0;
    padding: 0;
    font-family: inherit;
}

select {
    font-family: "Noto Sans", sans-serif;
    font-size: 12pt;
    border: none;
    border-radius: 2px;
    background-color: #eeeeee;
    width: 240px;
    height: 35px;
    line-height: 35px;
    margin: 10px 0;
}

h1 {
    font-family: 'Kalam', cursive;
    font-size: 30pt;
    font-weight: normal;
    text-align: center;
    margin: 0;
    padding: 0;
    flex-grow: 0;
    flex-shrink: 0;
    height: 55px;
    background-color: #eeeeee;
    border-bottom: none;
}

div.range {
    font-family: 'Noto Sans', sans-serif;
    font-size: 12pt;
    flex-grow: 0;
    flex-shrink: 0;
    height: 55px;
    line-height: 55px;
    text-align: center;
    background-color: #eeeeee;
    border-top: 1px solid grey;
}

input.login {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    height: 35px;
    line-height: 35px;
    width: 138px;
    margin:  10px 0;
    padding: 0 10px;
    text-align: left;
    border: 1px solid grey;
    border-radius: 10px;
    background-color: white;
}


p.login {
    font-family: 'Noto Sans', sans-serif;
    font-size: 16px;
    line-height: 35px;
    height: 35px;
    padding: 0;
    margin: 11px 0;
    text-align: left;
    background: none;
    border-radius: 3px;
}

input.startmonth {
    font-family: 'Noto Sans', sans-serif;
    font-size: 12pt;
    width: 65px;
    margin: 10px;
    text-align: center;
    border: 1px dotted grey;
    background: none;
    border-radius: 3px;
}

input.timespan {
    font-family: 'Noto Sans', sans-serif;
    font-size: 12pt;
    width: 25px;
    margin: 10px;
    text-align: center;
    border: 1px dotted grey;
    background: none;
    border-radius: 3px;
}

.root {
}

.app {
    display: flex;
    min-width: 1100px;
    min-height: 500px;
    width: 100vw;
    height: 100vh;
    margin: 0;
    border: none;
    overflow: hidden;
}

.app-login {
    display: grid;
    grid-template-columns: 90px 160px;
    padding: 20px;
    width: 250px;
    margin: calc(50vh - 123px) calc(50vw - 146px);
    background-color: white;
    border: 1px solid grey;
    border-radius: 5px;
    overflow: hidden;
}

.calendar-container {
    width: 365px;
    flex-grow: 0;
    flex-shrink: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-radius: 0;
    overflow: hidden;
    border-width: 0 1px 0 0;
    border-style: solid;
    border-color: grey;
}

.text-field-container {
    background-color: lightblue;
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0;
    border-radius: 0;
    overflow: hidden;
    border: none;
    display: flex;
    flex-direction: column;
}

.text-field-heading {
    padding: 0 0 0 40px;
    height: 55px;
    line-height: 85px;
    font-weight: bold;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: left;
    border-bottom: none;
}

.status {
    height: 30px;
    line-height: 30px;
    border-bottom: 1px solid grey;
}

.text-field-status {
    padding: 0 5px;
    font-size: 8pt;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: right;
    color: grey;
}

.calendar-status {
    padding: 0 5px;
    font-size: 12pt;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    background-color: #eeeeee;
}

.text-field-footer {
    padding: 0 10px;
    height: 55px;
    line-height: 55px;
    font-weight: bold;
    flex-grow: 0;
    flex-shrink: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.text-field {
    flex-grow: 1;
    flex-shrink: 1;
    font-family: 'Cousine', monospace;
    font-size: 15px;
    padding: 10px;
    margin: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid grey;
    outline: none;
    overflow: auto;
}
.text-field:focus {
    border-top: 2px solid grey;
    border-bottom: 2px solid grey;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
}

.header {
    font-weight: bold;
    text-align: center;
    line-height: 35px;
}

.smallHeader {
    font-size: 8pt;
    font-weight: normal;
}

.day {
    text-align: center;
    background-color: #eeeeee;
    line-height: 35px;
    border-radius: 10px;
    grid-row: span 2;
}

.day-today {
    border: 1px solid #dd0000;
    line-height: 33px;
}

.day-highlighted {
    background-color: lightblue;
}

.day-sunday {
    color: #0000ff;
}

.week {
    text-align: center;
    line-height: 35px;
    font-size: 10pt;
    grid-row: span 2;
    grid-column: 2;
}

.month {
    text-align: center;
    background: linear-gradient(to bottom, #ffffff, #cccccc);
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    border-right: 1px solid #cccccc;
    padding-top: 60px;
}
